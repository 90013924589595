import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CommonIconModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/common-icon";
import { FooterComponent } from "./footer.component";

@NgModule({
	imports: [CommonModule, CommonIconModule],
	declarations: [FooterComponent],
	exports: [FooterComponent],
})
export class FooterModule {}
