<div class="relative z-10 mt-8 inline-flex w-full flex-col items-center justify-center gap-4 sm:mt-12">
	<img class="h-24 w-24" src="/assets/images/profil_emh.svg" alt="profil EMH" />
	<div class="relative flex h-[47px] flex-col items-center justify-center gap-2 self-stretch" #menuWrapper>
		<div class="font-futura self-stretch text-center text-sm font-medium text-neutral-400">Bonjour</div>
		<div class="inline-flex items-center justify-center gap-2 self-stretch">
			<div class="relative h-5 w-5"></div>
			@if (user) {
				<button
					class="font-intro text-center text-base font-bold text-neutral-800"
					(click)="openCloseMenu($event)"
				>
					{{ user.givenName | uppercase }} {{ user.familyName | uppercase }}
				</button>
			}
			<button class="flex items-center justify-center" (click)="openCloseMenu($event)">
				<cp-common-icon
					class="text-[1.375rem] text-neutral-400"
					[name]="'expand_more'"
					[isFilled]="true"
				></cp-common-icon>
			</button>
		</div>
		<!-- SUBMENU -->
		<div
			class="absolute top-14 inline-flex w-[183px] flex-col items-start justify-start rounded-2xl bg-white shadow"
			#submenu
			[class.hidden]="!isMenuOpen"
			(click)="$event.stopPropagation()"
		>
			<a
				class="inline-flex items-center justify-center gap-2 self-stretch px-3 py-2"
				href="https://www.est-metropole-habitat.fr/faq"
			>
				<div class="opacity-40 hover:opacity-100">
					<cp-common-icon class="text-primary text-2xl" [name]="'help'" [isFilled]="true"></cp-common-icon>
				</div>
				<div class="font-futura shrink grow basis-0 text-start text-sm font-medium text-zinc-600">Aide</div>
			</a>
			@if (user && user.hasAdminRole && user.isImpersonated) {
				<button
					class="inline-flex items-center justify-center gap-2 self-stretch px-3 py-2"
					(click)="resetImpersonation()"
				>
					<div class="opacity-40 hover:opacity-100">
						<cp-common-icon
							class="text-primary text-2xl"
							[name]="'group'"
							[isFilled]="true"
						></cp-common-icon>
					</div>
					<div class="font-futura shrink grow basis-0 text-start text-sm font-medium text-zinc-600">
						Changer d'utilisateur
					</div>
				</button>
			}
			<button class="inline-flex items-center justify-center gap-2 self-stretch px-3 py-2" (click)="logout()">
				<div class="opacity-40 hover:opacity-100">
					<cp-common-icon class="text-primary text-2xl" [name]="'logout'" [isFilled]="true"></cp-common-icon>
				</div>
				<div class="font-futura shrink grow basis-0 text-start text-sm font-medium text-zinc-600">
					Se déconnecter
				</div>
			</button>
		</div>
		<!-- ### END SUBMENU -->
	</div>
</div>
<cp-rental-switcher
	class="z-1 relative mt-4 inline-flex w-full flex-col items-center justify-center px-12 sm:hidden"
	(selectionChanged)="handleNavItemClick()"
></cp-rental-switcher>
<nav class="menu mt-12 inline-flex w-full flex-col items-start justify-start gap-8 px-6">
	@if (navItems) {
		<ul class="inline-flex w-full flex-col items-start justify-start gap-8">
			@for (item of navItems; track item.name) {
				@if (item.isVisible) {
					<li class="w-full leading-4" [routerLinkActive]="'text-primary'">
						<a
							class="font-intro hover:text-primary inline-flex w-full shrink grow basis-0 items-center justify-start gap-4 self-stretch rounded-lg text-sm font-bold uppercase text-neutral-400"
							[routerLink]="item.routerLink"
							(click)="handleNavItemClick()"
							routerLinkActive="text-primary"
						>
							<cp-common-icon
								class="text-2xl"
								[name]="item.icon"
								[isFilled]="item.isIconFilled"
							></cp-common-icon>
							<span class="pt-0.5">{{ item.name | uppercase }}</span>
							@if (item.nbNotifications | async; as nbNotifications) {
								<div class="flex grow justify-end">
									<div class="bg-primary flex h-6 items-center rounded px-2">
										<div class="font-din text-sm font-bold text-white">{{ nbNotifications }}</div>
									</div>
								</div>
							}
						</a>
					</li>
				}
			}
		</ul>
	}
</nav>
