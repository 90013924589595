import { ChangeDetectionStrategy, Component, inject, Input } from "@angular/core";
import { CookiesModalComponent, CookiesModalModes } from "@e-tenant-hub/client-platform/shared/features/modals";
import { ResponsiveModalContainerService } from "@e-tenant-hub/client-platform/shared/ui/responsive/modal-container";

@Component({
	selector: "cp-footer",
	templateUrl: "./footer.component.html",
	styleUrl: "./footer.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
	@Input() mode: "over" | "side" = "over";
	private readonly modalService = inject(ResponsiveModalContainerService);

	openCookiesModal(): void {
		const config = {
			width: "66vw",
			maxWidth: "500px",
		};

		this.modalService.openDialog(CookiesModalComponent, CookiesModalModes.settings, config);
	}
}
