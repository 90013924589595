import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { RentalSwitcherModule } from "@e-tenant-hub/client-platform/shared/features/rental-switcher";
import { CommonIconModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/common-icon";
import { HeaderModule } from "@e-tenant-hub/client-platform/shell/ui/header";
import { UtilsModule } from "@e-tenant-hub/shared/utils";
import { NavBarComponent } from "./nav-bar.component";

@NgModule({
	imports: [CommonModule, RentalSwitcherModule, RouterModule, CommonIconModule, HeaderModule, UtilsModule],
	declarations: [NavBarComponent],
	exports: [NavBarComponent],
})
export class NavBarModule {}
