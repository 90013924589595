import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { ClientPlatformFormsModule } from "@e-tenant-hub/client-platform/shared/features/forms";
import { RentalSwitcherModule } from "@e-tenant-hub/client-platform/shared/features/rental-switcher";
import { IconButtonModule } from "@e-tenant-hub/client-platform/shared/ui/mobile/buttons/icon-button";
import { RoundedButtonModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/buttons/rounded-button";
import { CommonIconModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/common-icon";
import { CommonInputModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/inputs/common-input";
import { DatePickerInputsModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/inputs/date-picker-inputs";
import { SelectInputModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/inputs/select-input";
import { HeaderSearchToolbarComponent } from "./header-search-toolbar/header-search-toolbar.component";
import { HeaderComponent } from "./header.component";

@NgModule({
	imports: [
		CommonModule,
		MatIconModule,
		IconButtonModule,
		MatToolbarModule,
		CommonIconModule,
		RoundedButtonModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		CommonInputModule,
		RentalSwitcherModule,
		SelectInputModule,
		DatePickerInputsModule,
		ClientPlatformFormsModule,
	],
	declarations: [HeaderComponent, HeaderSearchToolbarComponent],
	exports: [HeaderComponent],
})
export class HeaderModule {}
